<template>
<div class="py-5">
  <div class="mapouter d-none d-md-block">
    <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=JMU%20Radio%20televizija%20vojvodine&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
  </div>
  <b-container class="mb-4">
    <b-row class="mt-3">
      <b-col cols="12">
        <h1>{{contactData.title}}</h1>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="5">
        <p>{{ contactData.address1 }}</p>
        <p>{{ contactData.address2 }}</p>
        <p>{{ contactData.address3 }}</p>
        <p><a :href="'mailto:' + contactData.email + '?subject=%5BDMN%5D%20Contact%20page'">{{ contactData.email }}</a></p>
        <p><a :href="contactData.phoneTelAttr">{{ contactData.phone }}</a></p>
      </b-col>
      <b-col cols="7">
        <b-form @submit="onSubmit" autocomplete="off">
          <b-form-group label-for="contactName">
            <b-input id="contactName" :state="contactNameState"  v-model="contactName"  :placeholder="$t('contact.enter-your-name')"  size="lg" trim></b-input>
            <b-form-invalid-feedback :state="contactNameState">{{ $t('contact.enterNameError') }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="contactName">
            <b-input id="contactEmail" :state="contactEmailState" v-model="contactEmail" :placeholder="$t('contact.enter-your-email')"  size="lg" trim></b-input>
            <b-form-invalid-feedback :state="contactEmailState">{{ $t('contact.enterEmailError') }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="contactMessage">
            <b-form-textarea id="contactMessage" :state="contactMessageState" class="my-2" v-model="contactMessage" :placeholder="$t('contact.enter-your-message')" size="lg" cols="80" rows="7" trim></b-form-textarea>
            <b-form-invalid-feedback :state="contactMessageState">{{ $t('contact.enterMessageError') }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <b-overlay :show="saveSpinner" spinner-small spinner-variant="primary" opacity="0.5" class="d-inline-block">
              <b-button id="contactSendButton" type="submit" size="lg" variant="primary">{{ $t('contact.send')}}</b-button>
            </b-overlay>
          </b-form-group>
          <b-form-group>
            <div v-if="operation=='success'" class="d-inline-block ml-3">
              <div class="alert alert-success p-3 success text-center">
                <BIconCheckCircle /> {{ $t('contact.success') }}
              </div>
            </div>
            <div v-if="operation=='error'" class="d-inline-block ml-3">
              <div class="alert alert-danger p-3 error">
                <BIconExclamationCircle /> {{ $t('contact.error') }}
              </div>
            </div>
          </b-form-group>
        </b-form>

      </b-col>

    </b-row>
  </b-container>
  <div class="mapouter d-md-none">
    <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=JMU%20Radio%20televizija%20vojvodine&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
  </div>
</div>
</template>

<script>
import { BFormTextarea, BIconCheckCircle, BIconExclamationCircle } from 'bootstrap-vue';
export default {
  name: "Contact",
  components: { BFormTextarea, BIconCheckCircle, BIconExclamationCircle},
  data() {
    return {
      contactData: {},
      contactName: "",
      contactEmail: "",
      contactMessage: "",
      contactNameState: null,
      contactEmailState: null,
      contactMessageState: null,
      saveSpinner: false,
      operation: null
    }
  },
  created() {
    this.contactData = this.getContactData(this.lang.IANA);
  },
  methods: {
    getContactData(selectedLang) {

     if (selectedLang === "sr-Cyrl") {
       return {
          title: "Контакт",
          address1: "Радио-телевизија Војводине",
          address2: "Каменички пут 45",
          address3: "21132 Нови Сад - Петроварадин",
          email: "dmn@rtv.rs",
          phone: "+381 21 290 25 33",
          phoneTelAttr: "tel:+381212902533"
       }
     } else if (selectedLang === "sr-Latn") {
       return {
          title: "Kontakt",
          address1: "Radio-televizvija Vojvodine",
          address2: "Kamenički put 45",
          address3: "21132 Novi Sad - Petrovaradin",
          email: "dmn@rtv.rs",
          phone: "+381 21 290 25 33",
          phoneTelAttr: "tel:+381212902533"
       }
     } else if (selectedLang === "en-GB") {
       return {
          title: "Contact",
          address1: "Radio Television Vojvodina",
          address2: "Kamenicki put 45",
          address3: "21132 Novi Sad - Petrovaradin",
          email: "dmn@rtv.rs",
          phone: "+381 21 290 25 33",
          phoneTelAttr: "tel:+381212902533"
       }
     }
      
    },

    onSubmit(event) {
      event.preventDefault();
      if (this.validateForm()) {
        console.log("Saljem")
        this.saveSpinner = true;
        
        const contactData = {}
        contactData.contactName = this.contactName;
        contactData.contactEmail = this.contactEmail;
        contactData.contactMessage = this.contactMessage;
  
        this.$http.post("/api/contact/send", contactData).then(r => {
          if (r.data) {
            if (r.data === "OK") {
              this.operation = "success";
            } else {
              this.operation = "error";
            }
            document.getElementById("contactSendButton").disabled = true;
          }
        }).catch(error => { this.handleError(error); })

        this.saveSpinner = false;
      }
    }, 

    validateForm() {
      const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (typeof this.contactName === "undefined" || this.contactName.length === 0) {
          this.contactNameState = false;
          document.getElementById("contactName").focus();
          document.getElementById("contactName").scrollIntoView({ block: 'center',  behavior: 'smooth' });
          return false;
      } else {
          this.contactNameState = null;
      }

      if (typeof this.contactEmail === "undefined" || this.contactEmail.length === 0 || !emailRegEx.test(this.contactEmail)) {
          this.contactEmailState = false;
          document.getElementById("contactEmail").focus();
          document.getElementById("contactEmail").scrollIntoView({ block: 'center',  behavior: 'smooth' });
          return false;
      } else {
          this.contactEmailState = null;
      }

      if (typeof this.contactMessage === "undefined" || this.contactMessage.length === 0) {
          this.contactMessageState = false;
          document.getElementById("contactMessage").focus();
          document.getElementById("contactMessage").scrollIntoView({ block: 'center',  behavior: 'smooth' });
          return false;
      } else {
          this.contactMessageState = null;
      }
      return true;

    }
  },

  metaInfo: function() {
    return {
      title: this.$t('general.full-name') + " - " + this.contactData.title
    }
  },

  computed: {
    lang: function() {
      return this.$root.lang
    }
  },

  watch: {
    async lang(newLang, oldLang) {
      this.contactData = this.getContactData(newLang.IANA);
    }
  }
}
</script>

<style>
  p {margin-bottom: .5rem;}
  .alert {border-radius: 8px !important; width: 100% !important;}
  .error {background-color: #F31B52 !important; color: #F4F5F7;}
  .success {background-color: #3CCA98 !important; color: #F4F5F7;}
</style>
